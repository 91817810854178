import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
  ) { }

  verifyWithdraw(): Observable<any> {
    const options = this.baseService.getHttpOptions();
    return this.http.get(this.baseService.baseurl + '/params/verify-withdraw', options);
  }

  verifyParameter(): Observable<any> {
    const options = this.baseService.getHttpOptions();
    return this.http.get(this.baseService.baseurl + '/params/verify-parameter', options);
  }
}
