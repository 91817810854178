export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  // {
  //   id: 'dashboard',
  //   icon: 'iconsminds-pie-chart-3',
  //   label: 'Dashboard',
  //   to: '/app/dashboard'
  // },
  {
    id: 'cadastros',
    icon: 'iconsminds-file-edit',
    label: 'Cadastros',
    to: '/app/cadastros',
    subs: [
      {
        id: 'alunos',
        icon: 'iconsminds-student-male',
        label: 'Alunos',
        to: '/app/alunos',
      },
      {
        icon: 'iconsminds-business-man',
        label: 'Colaboradores',
        to: '/app/cadastros/colaboradores'
      },
      {
        icon: 'iconsminds-diploma-2',
        label: 'Meus Cursos',
        to: '/app/cadastros/meus-cursos'
      },
      {
        icon: 'iconsminds-sharethis',
        label: 'Meus Polos',
        to: '/app/cadastros/meus-polos'
      },
      {
        icon: 'iconsminds-conference',
        label: 'Controle de acesso',
        to: '/app/operacional/grupos'
      }
    ]
  },
  {
    id: 'operacional',
    icon: 'iconsminds-gear',
    label: 'Operacional',
    to: '/app/operacional',
    subs: [
      {
        icon: 'iconsminds-bar-chart-4',
        label: 'Gerenciar Vendas',
        to: '/app/operacional/vendas'
      },
      {
        icon: 'iconsminds-puzzle',
        label: 'Vender',
        to: '/app/operacional/vendas/registrar'
      },
      {
        icon: 'iconsminds-dollar-sign-2',
        label: 'Saldo/Extrato',
        to: '/app/operacional/saldo-extrato'
      },
      {
        icon: 'iconsminds-speach-bubble',
        label: 'Chat',
        to: '/app/operacional/chats'
      },
      {
        icon: 'iconsminds-financial',
        label: 'Solicitações de saque',
        to: '/app/operacional/solicitacoes'
      },
      {
        icon: 'iconsminds-cart-quantity',
        label: 'Saques efetuados',
        to: 'operacional/saques-efetuados'
      },
      // {
      //   icon: 'iconsminds-receipt-4',
      //   label: 'Envios Notas Fiscais',
      //   to: 'operacional/list-nf'
      // }
    ]
  },
  {
    id: 'relatorios',
    icon: 'iconsminds-statistic',
    label: 'Relatórios',
    to: '/app/relatorios',
    subs: [
      {
        icon: 'iconsminds-security-check',
        label: 'Adimplência',
        to: '/app/relatorios/adimplencia',
      },
      {
        icon: 'iconsminds-diploma-2',
        label: 'Cursos',
        to: '/app/relatorios/cursos',
      },
      {
        icon: 'iconsminds-notepad',
        label: 'Inadimplência',
        to: '/app/relatorios/inadimplencia',
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'Meu extrato (GMV)',
        to: '/app/relatorios/meu-extrato-gmv',
      },
      {
        icon: 'iconsminds-student-male',
        label: 'Moodle',
        to: '/app/relatorios/moodle'
      },
      {
        icon: 'iconsminds-receipt-4',
        label: 'Parcelas Pagas',
        to: '/app/relatorios/parcelas-pagas'
      }
    ]
  }
];
export default data;
