<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <div class="user d-inline-block">
      <img alt="Profile Picture" class="img-thumbnail ml-4" [src]="layoutService.getLoggedUserPhoto()" />
    </div>
    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

    <div class="ml-4 d-xs-flex d-none flex-column">
      <h5 class="no-line-break header-text-size-md" style="margin-bottom: 0;">Olá, {{displayName}}!</h5>
      <span class="no-line-break header-text-size-sm">{{businessName}}</span>
      <div class="dropdown">
        <button class="c-pointer font-weight-bold no-line-break header-text-size-sm dropDownBtnIEData"
          id="dropdownMenuButon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{user.Business.business_type === 'IE' ? 'Dados da Instituição' : 'Dados do Polo'}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a routerLink="/app/cadastros/colaborador/{{this.user.id}}" class="dropdown-item c-pointer">
            Meus Dados
          </a>
          <a routerLink="/app/cadastros/polo/{{this.user.Business.id}}" class="dropdown-item c-pointer"
            *ngIf="layoutService.userIs(['POLO'])">
            Meu polo
          </a>
          <a routerLink="/app/perfil/ie" class="dropdown-item c-pointer">
            {{user.Business.business_type === 'IE' ? 'Dados de ' + user.Business.business_name : 'Minha IE'}}
          </a>
        </div>
      </div>
    </div>
    <div class="header-icons d-xs-none d-inline-block align-middle mt-1 pl-2">
      <div class="position-relative d-inline-block">
        <div dropdown>
          <button id="button-basic" dropdownToggle type="button" class="header-icon btn btn-empty">
            <i class="simple-icon-user"></i>
          </button>
          <div>
            <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-left">
              <div class="d-flex flex-row notification-dropdown-content">
                <div class="pl-3">
                  <p class="font-weight-medium mb-2">Olá, {{displayName}}!</p>
                  <p class="mb-2 text-small">
                    <a routerLink="/app/cadastros/colaborador/{{this.user.id}}">
                      Meus Dados
                    </a>
                  </p>
                  <p class="mb-2 text-small">
                    <a routerLink="/app/cadastros/polo/{{this.user.Business.id}}"
                      *ngIf="layoutService.userIs(['POLO'])">
                      Meu polo
                    </a>
                  </p>
                  <p class="text-muted mb-0 text-small">
                    <a routerLink="/app/perfil/ie">
                      {{user.Business.business_type === 'IE' ? 'Dados de ' + user.Business.business_name : 'Minha IE'}}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle mt-2 mr-2">
      <div class="position-relative d-inline-block">
        <div dropdown>
          <button id="button-basic" dropdownToggle (click)="readNotifications()" type="button" id="notificationButton"
            class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
            <span class="count">{{unreadNotifications}}</span>
          </button>
          <div *ngIf="notifications.length > 0">
            <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3"
              id="notificationDropdown">
              <div class="d-flex flex-row notification-dropdown-content" *ngFor="let notification of notifications">
                <div class="pl-3">
                  <p class="font-weight-medium mb-1">{{notification.title}}</p>
                  <p class="text-muted mb-0 text-small">{{notification.message}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user d-inline-block">
      <button class="btn btn-outline-theme-primary" (click)="onSignOut()">Sair</button>
    </div>
  </div>
</nav>