import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
  ) { }

  login(data): Observable<any> {
    const options = this.baseService.getHttpOptions();
    return this.http.post(this.baseService.baseurl + '/auth/login', JSON.stringify(data), options);
  }

  alterarSenha(data): Observable<any> {
    const options = this.baseService.getHttpOptions();
    return this.http.post(this.baseService.baseurl + '/auth/alterar-senha', JSON.stringify(data), options);
  }

  isLoggedIn() {
    return this.baseService.isLoggedIn();
  }

  logout() {
    this.baseService.logout();
  }

  setUser(user) {
    this.baseService.setUser(user);
  }

  getUser() {
    return this.baseService.getUser();
  }
}
