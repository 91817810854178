import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
  ) { }

  list(): Observable<any> {
    const options = this.baseService.getHttpOptions();
    return this.http.get(this.baseService.baseurl + '/notification/list', options);
  }

  read(notifications): Observable<any> {
    const options = this.baseService.getHttpOptions();
    return this.http.post(this.baseService.baseurl + '/notification/read', notifications, options);
  }
}
