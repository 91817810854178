import { Injectable } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as moment from 'moment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public editor = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      [{ 'color': [] }],
      [{ 'align': [] }],
      ['link'],
      ['clean'],
    ]
  };

  public currency = {
    default: { prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left', allowNegative: false },
    negative: { prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left', allowNegative: true },
    percent: { prefix: '', thousands: '.', decimal: ',', align: 'left', max: 100, allowNegative: false, suffix: '%', precision: 4 }
  }

  constructor(
    private notifications: NotificationsService,
    public authService: AuthService,
  ) {
  }

  async resizeImg(file: Blob, width: number, height: number): Promise<String> {
    let img = document.createElement("img");
    img.src = await new Promise<any>(resolve => {
      let reader = new FileReader();
      reader.onload = (e: any) => resolve(e.target.result);
      reader.readAsDataURL(file);
    });
    await new Promise(resolve => img.onload = resolve)
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    ctx.canvas.width = width;
    ctx.canvas.height = height;
    ctx.drawImage(img, 0, 0);
    var imgSize = Math.min(img.width, img.height);
    var left = (img.width - imgSize) / 2;
    var top = (img.height - imgSize) / 2;
    ctx = canvas.getContext("2d");
    ctx.drawImage(img, left, top, imgSize, imgSize, 0, 0, ctx.canvas.width, ctx.canvas.height);
    let result = await new Promise<String>(resolve => { resolve(canvas.toDataURL('image/png', 0.80)); });
    return result;
  }

  resolveS3Url(url) {
    return `https://s3.us-east-2.amazonaws.com/${url}`;
  }

  getPhoto(photo) {
    return photo != null ?
      `https://s3.us-east-2.amazonaws.com/${photo}` :
      '/assets/img/profile-pic-l.jpg';
  }

  getLoggedUserPhoto() {
    const user = this.authService.getUser();
    if (user) {
      return user.photo != null ?
        `https://s3.us-east-2.amazonaws.com/${user.photo}` :
        '/assets/img/profile-pic-l.jpg';
    }
    return '/assets/img/profile-pic-l.jpg';
  }

  loading(button, status) {
    button.disabled = status ? true : false;
    button.state = status ? 'show-spinner' : '';
  }

  userIs(userTypes) {
    let user = this.authService.getUser();
    var n = userTypes.includes(user.Business.business_type);
    return n;
  }

  isSysAdmin() {
    const user = this.authService.getUser();
    var isSysAdmin = user.is_sys_admin
    return isSysAdmin;
  }

  fixDate(date) {
    if (date) {
      if (date.indexOf('T') > -1) {
        return moment(date.split('T')[0], 'YYYY-MM-DD').format('DD/MM/YYYY');
      }
    }
    return date;
  }

  success(msg) {
    this.notifications.create('Sucesso!', msg || 'Sucesso...',
      NotificationType.Success,
      { timeOut: 3000, showProgressBar: true }
    );
  }

  error(msg) {
    this.notifications.create('Ops...', msg || 'Erro Desconhecido...',
      NotificationType.Error,
      { timeOut: 3000, showProgressBar: true }
    );
  }

}
