import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BaseService {
  baseurl = environment.baseurl;

  constructor(
  ) {
  }

  getHttpOptions() {
    const options = {
      headers: new HttpHeaders(this.getHeaders()),
      withCredentials: false
    }
    return options;
  };

  getCsvHttpOptions() {
    const options = {
      headers: new HttpHeaders(this.getHeaders()),
      responseType: 'text' as 'json',
      withCredentials: false
    }
    return options;
  };

  getHeaders() {
    const headers = {
      'Content-Type': 'application/json',
    };
    if (this.isLoggedIn()) {
      headers['authorization'] = `${this.getUser().token}`;
    }
    return headers;
  }

  isLoggedIn() {
    const result = this.getUser();
    return result ? true : false;
  }

  logout() {
    window.localStorage.clear();
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }
}
